<template>
  <div class="fv-row position-relative">
    <div class="prefix-slot-wrapper">
      <slot name="prefix"></slot>
    </div>

    <b-form-input
      :class="{'error': !!error, 'valid': !!value && !error, 'rounded-xxl': inputRoundedSize === 'lg' }"
      :value="value"
      trim
      :type="showPassword ? 'text' : inputType"
      @focus="inputActive = true"
      @blur="inputActive = false"
      @keydown="onKeydown"
      @paste="onPaste"
      @input="$emit('input', $event)"
    />

    <div
      v-if="inputType === 'password'"
      class="position-absolute password-toggle-wrapper"
      @click="showPassword = !showPassword"
    >
      <inline-svg :src="`/media/svg/icons/Custom/${showPassword ? 'Opened' : 'Closed'}-eye.svg`"/>
    </div>
    <label :class="{ 'active': inputActive || !!value, 'error': error, 'text-green': !!value && !error }">{{ placeholder }}</label>
    <div v-if="!!error" class="text-danger mt-1">
      <inline-svg src="/media/svg/icons/General/Attention.svg"/>
      {{ $t(error) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {type: String, default: ''},
    inputType: {type: String, default: 'text'},
    value: {type: String, default: ''},
    error: {type: String, default: ''},
    mode: {type: String, default: ''},
    inputRoundedSize: {type: String, default: 'sm'},
  },
  data: () => ({
    inputActive: false,
    showPassword: false
  }),
  methods: {
    onKeydown(event) {
      const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Enter'];
      const char = event.key;

      if (this.inputType === 'email') {
        const pattern = /[a-zA-Z_%+-@]/;
        if (!pattern.test(char) && !allowedKeys.includes(char)) {
          event.preventDefault();
        }
      }

      if (this.inputType === 'number') {
        if (!char.match(/^\d$/) && !allowedKeys.includes(char)) {
          event.preventDefault();
        }
      }

      if (this.mode === 'childAge') {
        if (allowedKeys.includes(char)) {
          return;
        }

        if (!char.match(/^\d$/)) {
          event.preventDefault();
          return;
        }

        const potentialValue = parseInt(this.value + char);
        if (potentialValue > 12) {
          event.preventDefault();
          return;
        }
      }
    },
    onPaste(event) {
      if (this.mode === 'childAge') {
        event.preventDefault();
      }
    }
  }
};
</script>

<style scoped>
input {
  height: 60px;
  padding: 16px 16px 0;
  font-size: 16px;
  color: #6E7173 !important;
  background-color: #FFF !important;
  border: 1px solid #6E7173 !important;

  &.error {
    border-color: #E90900 !important;
  }

  &.valid {
    border-color: #008E49 !important;
  }
}

label {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: transform 0.2s, font-size 0.2s, color 0.2s;

  &.active {
    transform: translate(-3px, -14px);
    font-size: 12px;
    color: #333;
  }

  &.error {
    color: #E90900;
  }
}

.password-toggle-wrapper {
  right: 16px;
  top: 18px;
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  input {
    height: 45px;
    font-size: 14px;
  }

  label {
    top: 12px;
    font-size: 14px;

    &.active {
      transform: translate(-3px, -7px);
      font-size: 11px;
    }
  }

  .password-toggle-wrapper {
    right: 16px;
    top: 10px;

    svg {
      width: 18px;
    }
  }
}

.prefix-slot-wrapper {
  position: absolute;
  height: 100%;
}

// Hide number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>